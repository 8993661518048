const rulesBtns = document.getElementsByClassName("rules-btns__item");

if (rulesBtns) {
  for (let btn of rulesBtns) {
    btn.addEventListener("click", function () {
      for (let btn of rulesBtns) {
        btn.classList.remove("active");
      }
      this.classList.add("active");
    });
  }
}
