
let expandBtn = document.querySelector(".expand");
let ticketWrap = document.querySelector(".tickets__wrap");

if(expandBtn) {
  expandBtn.addEventListener("click", function () {
    ticketWrap.classList.toggle("block");
  });
}

