const btns = document.getElementsByClassName("header__lang-item");

for (let btn of btns) {
  btn.addEventListener("click", function () {
    for (let btn of btns) {
      btn.classList.remove("active");
    }
    this.classList.add("active");
  });
}

// бургер меню

let menuBtn = document.querySelector(".header__burger");
let menu = document.querySelector("#header__main");
let menuContent = menu.querySelectorAll(".header__lang-item, a, .header__cart");

let body = document.querySelector("body");

menuBtn.addEventListener("click", function () {
  menuBtn.classList.toggle("active");
  menu.classList.toggle("active");
  body.classList.toggle("menu-opened");
});

menuContent.forEach((item) =>
  item.addEventListener("click", function () {
    menu.classList.remove("active");
    menuBtn.classList.remove("active");
    body.classList.remove("menu-opened");
  })
);

// search

let searchBtn = body.querySelector(".header-search__btn");
let searchCloseBtn = body.querySelector(".header-search__close");

searchBtn.addEventListener("click", function () {
  body.classList.add("search_active");
});

searchCloseBtn.addEventListener("click", function () {
  body.classList.remove("search_active", "menu-opened");
  menu.classList.remove("active");
  menuBtn.classList.remove("active");
});

