let tickets = document.querySelector(".tickets");
let count = document.querySelectorAll(".proposed-item__input");

if (tickets) {
  tickets.addEventListener("click", function (e) {
    if (e.target.classList.contains("plus")) {
      ++e.target.parentElement.querySelector("input").value;
      if (e.target.parentElement.querySelector("input").value >= 4) {
        e.target.parentElement.querySelector("input").value = "4";
        e.target.classList.add("disabled");
      }
      if (
        e.target.parentElement
          .querySelector(".minus")
          .classList.contains("disabled") &&
        e.target.parentElement.querySelector("input").value !== "1"
      ) {
        e.target.parentElement
          .querySelector(".minus")
          .classList.remove("disabled");
      }
    } else if (e.target.classList.contains("minus")) {
      --e.target.parentElement.querySelector("input").value;
      if (e.target.parentElement.querySelector("input").value <= 1) {
        e.target.parentElement.querySelector("input").value = "1";
        e.target.classList.add("disabled");
      }
      if (
        e.target.parentElement
          .querySelector(".plus")
          .classList.contains("disabled") &&
        e.target.parentElement.querySelector("input").value !== "1"
      ) {
        e.target.parentElement
          .querySelector(".plus")
          .classList.remove("disabled");
      }
    }
  });
  const inputConditions = function (event) {
    if (event.currentTarget.value <= 1) {
      event.target.parentElement.querySelector("input").value = "1";
      event.target.parentElement
        .querySelector(".minus")
        .classList.add("disabled");
    }
    if (event.currentTarget.value >= 4) {
      event.target.parentElement.querySelector("input").value = "4";
      event.target.parentElement
        .querySelector(".plus")
        .classList.add("disabled");
    }
    if (
      event.target.parentElement
        .querySelector(".minus")
        .classList.contains("disabled") &&
      event.currentTarget.value !== "1"
    ) {
      event.target.parentElement
        .querySelector(".minus")
        .classList.remove("disabled");
    }
    if (
      event.target.parentElement
        .querySelector(".plus")
        .classList.contains("disabled") &&
      event.currentTarget.value !== "4"
    ) {
      event.target.parentElement
        .querySelector(".plus")
        .classList.remove("disabled");
    }
  };

  count.forEach((item) => {
    item.addEventListener("blur", inputConditions),
      item.addEventListener("change", inputConditions),
      item.addEventListener("keypress", function (event) {
        if (event.key === "Enter") {
          event.preventDefault();
          inputConditions(event);
        }
      });
  });
}
