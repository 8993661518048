let contactsInput = document.querySelectorAll(".contacts-item__input");

if (contactsInput) {
  contactsInput.forEach((item) => {
    item.addEventListener("change", function () {
      item.classList.add("active");

      if (item.value === "") {
        item.classList.remove("active");
      }
    });
  });
}
