let descrMoreBtn = document.querySelector(".about-text__more");
let descrLessBtn = document.querySelector(".about-text__less");
let aboutDescr = document.querySelector(".about__descr");


if (descrMoreBtn) {
  descrMoreBtn.addEventListener("click", function () {
    aboutDescr.classList.remove("more");
    descrMoreBtn.classList.remove("active");
    descrLessBtn.classList.add("active");
  });
}

if (descrLessBtn) {
  descrLessBtn.addEventListener("click", function () {
    aboutDescr.classList.add("more");
    descrMoreBtn.classList.add("active");
    descrLessBtn.classList.remove("active");
  });
}


if (aboutDescr) {
  if (aboutDescr.offsetHeight > 790) {
    descrMoreBtn.classList.add("active");
    aboutDescr.classList.add("more");
    descrLessBtn.classList.remove("active");
  }
}
